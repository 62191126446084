@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Nunito;
    src: url("../public/nunito.ttf") format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: Festive;
    src: url("../public/festive.ttf") format('truetype');
    font-display: fallback;
}

* {
    font-family: Nunito, serif;
    scrollbar-width: none;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

p {
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

*:focus {
    outline: 0;
}

.title {
    font-family: Festive, Nunito, serif;
}

/* width */
::-webkit-scrollbar {
    width: 0;
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 0;
}

/* Track */
::-webkit-scrollbar-track {
    background: #333333;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fff;
}
